import React from 'react'
import { graphql } from 'gatsby'

//STYLES
import '../styles/dynamic-pages-css/cloud-analytics.css'
import '../styles/dynamic-pages-css/common-styles.css'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import SectionBlock from '../components/sectionBlock'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import InterestedBlock from '../components/interestedBlock'
import { useTranslation } from 'react-i18next'

const CloudAnalytics = ({ data }) => {
  let md = data.markdownRemark.frontmatter
  const { t } = useTranslation()

  let sectionBlocks = md.sectionArray.map((item, i) => (
    <SectionBlock
      key={i}
      image={item.image.childImageSharp.fixed}
      blockTitle={item.blockTitle}
      blockText={item.blockText}
    />
  ))

  return (
    <div id="cloud-analytics">
      <BackgroundImage
        className="header-img"
        fluid={md.headerImage.childImageSharp.fluid}
        alt="Index header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{t('cloudAnalyticsTitle')}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="first-grid">
          <div className="innovation-text">
            <p className="blue-text">
              {t('cloudAnalyticsTitle').toUpperCase()}
            </p>
            <h2 className="section-title">{t('cloudAnalyticsSubTitle')}</h2>
            <p className="section-ingress">{t('cloudAnalyticsMainIngress')}</p>
            <p className="section-text">{t('cloudAnalyticsMainText')}</p>
          </div>
          <div className="innovation-img">
            <Img
              className="working-image gatsby-image"
              fluid={md.mainImage.childImageSharp.fluid}
              alt="workingspace"
            />
          </div>
        </div>
        <div className="video-section">
          <div className="video">
            <iframe
              src={md.video}
              allowtransparency="true"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              title="video-analytics"
            ></iframe>
          </div>
          <div className="video-text">
            <h2
              style={{ fontSize: '18px', fontWeight: '400', maxWidth: '500px' }}
            >
              {t('cloudAnalyticsVideoTitle')}
            </h2>
            <button style={{ marginTop: '20px' }} className="blue-border-btn">
              <a href={md.videoLink}>{md.linkText}</a>
            </button>
          </div>
        </div>
        <div className="main-section">
          <div className="first-section">
            <p className="blue-text">
              {t('cloudAnalyticsSectionTitle').toUpperCase()}
            </p>
            <h2 className="section-title">{t('cloudAnalyticsSectionH1')}</h2>
            <p className="section-text">{t('cloudAnalyticsSectionText')}</p>
          </div>
          <div className="second-section">{sectionBlocks}</div>
        </div>
        <InterestedBlock
          interestedBlueText={md.interestedBlueText}
          interestedTitle={md.interestedTitle}
          interestedText={md.interestedText}
        />
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        blueText
        mainH1
        mainIngress
        mainText
        sectionBlueText
        sectionH1
        sectionText
        interestedTitle
        interestedText
        interestedBlueText
        lang
        videoText
        videoTextH2
        video
        videoLink
        linkText
        sectionArray {
          blockTitle
          blockText
          image {
            childImageSharp {
              fixed(width: 175) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default CloudAnalytics
