import React from 'react'
import '../styles/template1.css'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const InterestedBlock = props => {
  const { t } = useTranslation()
  return (
    <div className="interested-block">
      <div style={{ maxWidth: '1170px', margin: '0 auto' }}>
        <h4 className="work-with-us-h4">
          {t('interestedBlockSubTitle').toUpperCase()}
        </h4>
        <h3 className="work-with-us-title">{t('interestedBlockTitle')}</h3>
        <p className="work-with-us-text">{t('interestedBlockText')}</p>
        <button className="work-with-us-btn">
          <Link className="work-btn" to="/contact">
            {t('interestedBlockButtonText')}
          </Link>
        </button>
      </div>
    </div>
  )
}

export default InterestedBlock
