import React from 'react'
import '../styles/template1.css'
import Img from 'gatsby-image'

const SectionBlock = props => {
  return (
    <div className="information-block">
      <span>
        <Img
          style={{ height: '45px', width: '45px' }}
          alt={props.blockTitle}
          fixed={props.image}
        />
      </span>
      <h4 className="block-title h4">{props.blockTitle}</h4>
      <p className="block-text">{props.blockText}</p>
    </div>
  )
}

export default SectionBlock
